import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import ObjectFitCoverImage from '../../molecules/common/ObjectFitCoverImage';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';

import * as styles from './index.module.css';

const AboutTemplate = ({ pageContext }) => {
  const { pagePath } = pageContext;

  const { aboutImage } = useStaticQuery(
    graphql`
      query {
        aboutImage: file(relativePath: { eq: "about-image.jpg" }) {
          publicURL
        }
      }
    `
  );

  return (
    <div className="root">
      <SEO path={pagePath} title="About TORCH" />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <article>
              <div className={styles['container']}>
                <div className={styles['imageWrapper']}>
                  <ObjectFitCoverImage
                    src={aboutImage.publicURL}
                    alt=""
                    width={1200}
                    height={630}
                  />
                </div>

                <div className={styles['contentArea']}>
                  <div className={styles['headingWrapper']}>
                    <h1 className={styles['heading']}>
                      <span className={styles['headingSub']}>About TORCH</span>
                      <span className={styles['headingMain']}>TORCHとは</span>
                    </h1>
                  </div>

                  <div className={styles['ledeWrapper']}>
                    <p className={styles['lede']}>
                      スポーツ×科学で
                      <br />
                      指導をアップデートするメディア
                      <br className={styles['ledeBr1']} />
                      『TORCH』
                    </p>
                  </div>

                  <div className={styles['paragraphWrapper']}>
                    <p className={styles['paragraph']}>
                      今、スポーツの世界にも、テクノロジーやサイエンスの力によって私たちが長くよりどころにしていた、経験や勘、根性論といった見えないものを裏付ける根拠が解明されスポーツ指導の現場が変わり始めています。
                    </p>
                    <p className={styles['paragraph']}>
                      防げるはずの怪我で苦しむ選手を減らし、ポテンシャルのある選手が本来の力を発揮できる世界に。一人でも多くの子どもたちが、大好きなスポーツを楽しく、そして長く続けられるように。
                    </p>
                    <p className={styles['paragraph']}>
                      部活動やジュニアスポーツの指導にかかわる皆さんと、私たちはともに考えていきたい。
                      <br />
                      TORCH はその道しるべとなる情報発信をめざします。
                    </p>
                  </div>

                  <section>
                    <div className={styles['subHeadingWrapper']}>
                      <h2 className={styles['subHeading']}>
                        <span className={styles['subHeadingSub']}>Themes</span>
                        <span className={styles['subHeadingMain']}>
                          TORCHが扱うテーマ
                        </span>
                      </h2>
                    </div>

                    <div className={styles['themeListWrapper']}>
                      <ul className={styles['themeList']}>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>
                            スポーツ科学
                          </div>
                        </li>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>
                            育成・発達
                          </div>
                        </li>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>
                            コーチング・指導論
                          </div>
                        </li>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>
                            怪我・リハビリ・安全
                          </div>
                        </li>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>
                            フィジカル強化
                          </div>
                        </li>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>
                            栄養・睡眠
                          </div>
                        </li>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>
                            スポーツメンタル
                          </div>
                        </li>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>
                            女子選手
                          </div>
                        </li>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>
                            自主自律（セルフマネジメント）
                          </div>
                        </li>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>
                            チームビルディング・リーダーシップ
                          </div>
                        </li>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>戦術</div>
                        </li>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>
                            アスリートキャリア
                          </div>
                        </li>
                        <li className={styles['themeListItem']}>
                          <div className={styles['themeListBadge']}>
                            スポーツテック
                          </div>
                        </li>
                      </ul>
                    </div>
                  </section>

                  <section>
                    <div className={styles['subHeadingWrapper']}>
                      <h2 className={styles['subHeading']}>
                        <span className={styles['subHeadingSub']}>
                          Membership
                        </span>
                        <span className={styles['subHeadingMain']}>
                          まずは無料会員登録
                        </span>
                      </h2>
                    </div>

                    <div className={styles['registerConductorWrapper']}>
                      <div className={styles['registerConductor']}>
                        <div className={styles['registerConductorLedeWrapper']}>
                          <p className={styles['registerConductorLede']}>
                            会員登録をすると、最新記事を
                            <br />
                            いち早く受け取ることができます。
                          </p>
                        </div>
                        <InlineBlockWrapper
                          className={
                            styles['registerConductorLinkButtonWrapper']
                          }
                        >
                          <div
                            className={styles['registerConductorLinkButton']}
                          >
                            <Link
                              className={
                                styles['registerConductorLinkButtonLabel']
                              }
                              to="/signup"
                            >
                              無料会員登録はこちら
                            </Link>
                          </div>
                        </InlineBlockWrapper>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </article>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </div>
  );
};

export default AboutTemplate;
